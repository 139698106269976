<template>
  <base-section id="theme-features">
    <base-section-heading title="FEATURED TRAINING FOR MEMBERS">
      Check out free training provided by Scrum On Demand members who are willing to share their knowledge and experiences on the topics below.
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            {{ feature.description }}
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          title: 'AGILE FUNDAMENTALS',
          description: 'Free training for members only. Learn the basics of Agile Principles, Methodology and Implementation Approach from experienced practitioners of the Agile practices. For beginners and those needing a quick refresher.',
          icon: 'mdi-clock-fast',
        },
        {
          title: 'POPULAR SOFTWARE DESIGN PATTERNS',
          description: 'Free training for members only. Learn the basics of software design patterns. Participate in group discussions on popular software platforms used for many web and mobile applications.',
          icon: 'mdi-monitor-dashboard',
        },
        // ,
        // {
        //   color: 'primary',
        //   dark: true,
        //   title: 'Easily Customizable',
        //   icon: 'mdi-pencil-box-outline',
        // },
        // {
        //   title: 'Image Parallax',
        //   icon: 'mdi-image-size-select-actual',
        // },
        // {
        //   title: 'Seo Optimized',
        //   icon: 'mdi-ice-pop',
        // },
        // {
        //   title: '24/7 Support',
        //   icon: 'mdi-help-circle-outline',
        // },
      ],
    }),
  }
</script>
